@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  min-height: 100vh;
  min-height: 100svh;
  background: #030016;
  color: #fff;
  font-family: 'Satoshi';
}

.min-h-main {
  min-height: calc(100vh - 7rem * 2);
}

.main {
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;
}

.border-gradient {
  position: relative;
}

.border-gradient::after {
  content: '';
  position: absolute;
  inset: 0;
  border: 1px solid white;
  border-radius: inherit;
  mask: linear-gradient(186.06deg, #FFFFFF -164.7%, rgba(255, 255, 255, 0) 164.57%);
}

.toast-position {
  top: 6rem !important;
}

.notification {
  position: relative;
  padding: 16px 15px 16px 22px;
  background-color: rgba(255, 255, 255, 0.1);
  /*background-color: black;*/
  border-radius: 4px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

.notification-left {
  display: flex;
}

.notification .text-container {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
}

.notification-img {
  cursor: pointer;
}

.notification-border {
  height: 100%;
  position: absolute;
  left: 1px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.notification .notification-border {
  border-left: 1px solid #8fffa8;
}

.notification._error .notification-border,
.notification._info .notification-border {
  border-left: 1px solid rgba(255, 158, 182, 1);
}

@keyframes slidein {
  from {
    opacity: 0;
    scale: 0;
  }

  to {
    opacity: 1;
    scale: 1;
  }
}

@keyframes slideOut {
  from {
    opacity: 1;
    scale: 1;
  }

  to {
    opacity: 0;
    scale: 0;
  }
}

.noHeight {
  height: 0px;
  overflow: hidden;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.input-number::-webkit-outer-spin-button,
.input-number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input-number[type=number] {
  -moz-appearance: textfield;
}